import React, {FormEvent, useState} from 'react';
import './App.scss';
import {useTranslation} from "react-i18next";
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import HttpApi from 'i18next-http-backend'
import MultiLoadBackendAdapter from 'i18next-multiload-backend-adapter'
import config from './i18n.common'
import {useMediaQuery} from "react-responsive";

declare global {
    interface Window {
        grecaptcha: any;
        sg: {
            launch: any
        };
    }
}
i18n.use(initReactI18next)
    .use(MultiLoadBackendAdapter)
    .init({
        //defaultNS: 'web',
        keySeparator: false,
        ns: config.ns,
        lowerCaseLng: true,
        backend: {
            backend: HttpApi,
            backendOption: {
                loadPath: 'https://api.oshcasino.com/api/translations/{{ns}}',
                customHeaders: () => ({
                    'accept-language': 'ru',
                    // ...
                }),
                parse: (dataString: any, language: any) => {
                    const data = JSON.parse(dataString)
                    const lang = language[0]
                    return {[lang]: data};
                }
            }
        },
        lng: "en",
        updateMissing: true,
        nsSeparator: ':',
        fallbackLng: false,
        ignoreJSONStructure: true
    });

function App() {
    const [menuOpen, setMenuOpen] = useState(false);

    const [name, setName] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [message, setMessage ] = useState<string>('');

    const [nameError, setNameError] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [messageError, setMessageError ] = useState<string>('');

    const [sended, setSended ] = useState<boolean>(false);


    const { t } = useTranslation();
    const isMobile = useMediaQuery({ maxWidth: 1150 });

    function scrollToSection(id: any, offset = 200) {
        if (isMobile) {
            setMenuOpen(false);
        }
        const section = document.getElementById(id);
        // @ts-ignore
        const top = section.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({ top: top - offset, behavior: 'smooth' });
    }

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();

        const data = {
            name,
            email,
            message,
        };

        try {
            const response = await fetch('https://oshcasino.com/api/contact-form/affiliates/send', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {
                setSended(true);
                setNameError('')
                setEmailError('')
                setMessageError('')
            } else {
                const responseData = await response.json();
                console.error('Error:', responseData.payload.constraints);
                if (responseData.payload.constraints.email) {
                    setEmailError(responseData.payload.constraints.email)
                }
                if (responseData.payload.constraints.name) {
                    setNameError(responseData.payload.constraints.name)
                }
                if (responseData.payload.constraints.message) {
                    setMessageError(responseData.payload.constraints.message)
                }
            }
        } catch (error) {
            console.error('Network error:', error);
        }
    };
  return (
    <div className="app">
      <header className="app-header">
          <div className="app-header__container">
              {isMobile && !menuOpen && (
                  <img draggable="false"
                       src="/svg/burger.svg"
                       alt="decoration"
                       loading="lazy"
                       className="app-header__menu--burger"
                       onClick={()=> setMenuOpen(!menuOpen)}
                  />
              )}
              {isMobile && menuOpen && (
                  <img draggable="false"
                       src="/svg/close.svg"
                       alt="decoration"
                       loading="lazy"
                       className="app-header__menu--close"
                       onClick={()=> setMenuOpen(!menuOpen)}
                  />
              )}
              <img draggable="false"
                   className="app-header__logo"
                   src="/logo.png"
                   alt="OSH logo"
                  loading="lazy"
              />
              {!isMobile && (
                  <div className="app-header__menu">
                      <div className="app-header__menu--item" onClick={() => scrollToSection('main', 125)}>{t('web.affiliate:main')}</div>
                      <div className="app-header__menu--item" onClick={() => scrollToSection('statistics', 200)}>{t('web.affiliate:stats')}</div>
                      <div className="app-header__menu--item" onClick={() => scrollToSection('affiliate-main', 50)}>{t('web.affiliate:affiliates')}</div>
                      <div className="app-header__menu--item" onClick={() => scrollToSection('contactUs', 200)}>{t('web.affiliate:contact us')}</div>
                  </div>
              )}
              {!isMobile && (
                  <div className="app-header__actions">
                      <a href='https://affiliates.oshcasino.com/login' target="_blank" rel="noopener noreferrer"
                         className="main-button logIn">
                          {t('web.affiliate:Log in')}
                      </a>
                      <a href='https://affiliates.oshcasino.com/registration' target="_blank" rel="noopener noreferrer"
                         className="main-button">
                          {t('web.affiliate:Become Partner')}
                      </a>
                  </div>
              )}
          </div>
          {isMobile && menuOpen && (
              <div className="app-header__menu-mobile">
                  <div className="app-header__menu-mobile--item"
                       onClick={() => scrollToSection('main', 400)}>{t('web.affiliate:main')}</div>
                  <div className="app-header__menu-mobile--item"
                       onClick={() => scrollToSection('statistics', 425)}>{t('web.affiliate:stats')}</div>
                  <div className="app-header__menu-mobile--item"
                       onClick={() => scrollToSection('affiliate-main', 400)}>{t('web.affiliate:affiliates')}</div>
                  <div className="app-header__menu-mobile--item"
                       onClick={() => scrollToSection('contactUs', 475)}>{t('web.affiliate:contact us')}</div>
                  <div className="app-header__actions">
                      <a href='https://affiliates.oshcasino.com/login' target="_blank" rel="noopener noreferrer"
                         className="main-button logIn">
                          {t('web.affiliate:Log in')}
                      </a>
                      <a href='https://affiliates.oshcasino.com/registration' target="_blank" rel="noopener noreferrer"
                         className="main-button">
                          {t('web.affiliate:Become Partner')}
                      </a>
                  </div>
              </div>
          )}
      </header>
        <div className={`app-main ${menuOpen && 'app-main--menu-open'}`}>
            <img draggable="false"
                 className="app-main__decoration2"
                 src="/logos/logoDecoration1.png"
                 alt="OSH logo"
                 loading="lazy"
            />
            <img draggable="false"
                 className="app-main__decoration1"
                 src="/logos/logoDecoration2.png"
                 alt="OSH logo"
                 loading="lazy"
            />
            <div className="screen">
                <img draggable="false"
                     className="screen__logo"
                   src="/logos/mainLogo.png"
                  alt="OSH logo"
                  loading="lazy"
              />
              <img draggable="false"
                   className="screen__decoration"
                   src="/logos/logoDecoration.png"
                   alt="OSH logo"
                   loading="lazy"
              />
          </div>
          <div className="welcome" id='main'>
              <div className="welcome__logo">
                  <img draggable="false"
                      src="/logos/secondLogo.png"
                      alt="OSH logo"
                      loading="lazy"
                  />
              </div>
              <div className="welcome__text">
                  {t('web.affiliate:Welcome to OSH Affiliates – your go-to spot for all things gaming! Dive into a world of fun with a wide range of slot machines, live casino action, and exciting shows. Explore our collection of games from top developers, ensuring there\'s something for everyone to enjoy.')}
              </div>
          </div>
          <div className="statistics" id='statistics'>
              <img draggable="false"
                   className="statistics__decoration"
                   src="/logos/statisticsDecoration.png"
                   alt="decor"
                   loading="lazy"
              />
              <div className="content-header">
                  <h6 className="content-header--text">
                      {t('web.affiliate:OSH CASINO')}
                  </h6>
                  <h4 className="content-header--name">
                      {t('web.affiliate:CHOICE BY PLAYERS')}
                      <img draggable="false"
                          className="statistics__header--character"
                          src="/img/character3.png"
                          alt="character"
                          loading="lazy"
                      />
                  </h4>
              </div>
              <div className="statistics__container">
                  <img draggable="false"
                      className="statistics__container--character2"
                      src="/img/character2.png"
                      alt="character"
                      loading="lazy"
                  />
                  <div className="statistics__item">
                      <div className="statistics__item--value">{t('web.affiliate:10 MIN')}</div>
                      <div className="statistics__item--name">{t('web.affiliate:Average WITHDRAW TIME')}</div>
                  </div>
                  <div className="statistics__item">
                      <div className="statistics__item--value">{t('web.affiliate:24/7')}</div>
                      <div className="statistics__item--name">{t('web.affiliate:ONLINE SUPPORT')}</div>
                  </div>
                  <div className="statistics__item">
                      <div className="statistics__item--value">{t('web.affiliate:2,400+')}</div>
                      <div className="statistics__item--name">{t('web.affiliate:Casino Games')}</div>
                      <img draggable="false"
                          className="statistics__item--character1"
                          src="/img/character1.png"
                          alt="character"
                          loading="lazy"
                      />
                  </div>
                  <div className="statistics__item">
                      <div className="statistics__item--value">{t('web.affiliate:20+')}</div>
                      <div className="statistics__item--name">{t('web.affiliate:DEPOSIT METHODS')}</div>
                  </div>
                  <div className="statistics__item">
                      <div className="statistics__item--value">{t('web.affiliate:100+')}</div>
                      <div className="statistics__item--name">{t('web.affiliate:GAMING PROVIDERS')}</div>
                  </div>
                  <div className="statistics__item">
                      <div className="statistics__item--value">{t('web.affiliate:100+')}</div>
                      <div className="statistics__item--name">{t('web.affiliate:SUPPORTED COUNTRIES')}</div>
                  </div>
              </div>
          </div>
          <div className="action">
              <div className="content-header">
                  <h6 className="content-header--text">
                      {t('web.affiliate:CAN’T DECIDE YET?')}
                  </h6>
                  <h4 className="content-header--name">
                      {t('web.affiliate:EXPLORE OSH CASINO')}
                  </h4>
              </div>
              <img draggable="false"
                   className="action__img"
                   src="/decorations/product.png"
                   alt="decoration"
                   loading="lazy"
              />
              <a href='https://oshcasino.com/en' target="_blank" rel="noopener noreferrer"
                 className="main-button">{t('web.affiliate:EXPLORE NOW')}</a>
          </div>
      </div>
        <div className="affiliate-main" id="affiliate-main">
            <div className="offer">
                <div className="offer__decoration4"/>
                <img draggable="false"
                    className="offer__decoration5"
                    src="/decorations/decoration4.png"
                    alt="decoration"
                    loading="lazy"
                />
                <div className="offer-header">
                    <h4 className="offer-header__name">{t('web.affiliate:WE OFFER')}</h4>
                    <h6 className="offer-header__text">{t('web.affiliate:Join the Osh Casino Affiliate Program!')}</h6>
                    <div className="offer-header__decoration"/>
                </div>
                <div className="offer-list">
                    <div className="offer-list__item">
                        <img draggable="false"
                            className="offer-list__decoration3"
                            src="/decorations/decoration3.png"
                            alt="decoration"
                            loading="lazy"
                        />
                        <div className="offer-list__container">
                            <div className="offer-list__name listName3">{t('web.affiliate:50% Revenue Share')}</div>
                            <div className="offer-list__text">{t('web.affiliate:Receive a profit share based on your referral performance. No Negative Carryover. No Admin Fees.')}</div>
                        </div>
                    </div>
                    <div className="offer-list__item">
                        <img draggable="false"
                            className="offer-list__decoration2"
                            src="/decorations/decoration2.png"
                            alt="decoration"
                            loading="lazy"
                        />
                        <div className="offer-list__container">
                            <div className="offer-list__name listName2">{t('web.affiliate:CPA Options & Hybrid Deals')}</div>
                            <div className="offer-list__text">{t('web.affiliate:Available upon request. Get paid')}</div>
                        </div>
                    </div>
                    <div className="offer-list__item">
                        <img draggable="false"
                            className="offer-list__decoration1"
                            src="/decorations/decoration1.png"
                            alt="decoration"
                            loading="lazy"
                        />
                        <div className="offer-list__container">
                            <div className="offer-list__name listName1">{t('web.affiliate:Sign-Up Bonus $100')}</div>
                            <div className="offer-list__text">{t('web.affiliate:5-minute review process')}</div>
                            <div className="offer-list__text">{t('web.affiliate:100% approval rate')}</div>
                            <div className="offer-list__text">{t('web.affiliate:Weekly and Biweekly Payments')}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="product">
                <div className="product__main">
                    <div className="product__main-item">
                        <div className="product__main-item--name">
                            {t('web.affiliate:OUR PRODUCT')}
                        </div>
                        <div className="product__main-item--text">
                            {t('web.affiliate:Slots, Live Casino, Table Games.')}
                        </div>
                    </div>
                    <div className="product__main-item">
                        <div className="product__main-item--name">
                            {t('web.affiliate:SOFTWARE')}
                        </div>
                        <div className="product__main-item--text">
                            {t('web.affiliate:1x2 Gaming, 4theplayer, Authentic Gaming, Amatic Industries, Avatar UX, Booon')}
                        </div>
                    </div>
                    <div className="product__main-item">
                        <div className="product__main-item--name">
                            {t('web.affiliate:Deposit METHODS')}
                        </div>
                        <div className="product__main-item--text">
                            {t('web.affiliate:Visa, MasterCard, Coinspaid (Bitcoin, Litecoin, Bitcoin Cash, Etherum, Dogecoin, Tether)')}
                        </div>
                    </div>
                    <div className="product__main-item">
                        <div className="product__main-item--name">
                            {t('web.affiliate:Withdraw METHODS')}
                        </div>
                        <div className="product__main-item--text">
                            {t('web.affiliate:Visa, MasterCard (please view Terms & Conditions on countries that are supported), Coinspaid')}
                        </div>
                    </div>
                    <div className="product__main-item">
                        <div className="product__main-item--name">
                            {t('web.affiliate:LANGUAGES')}
                        </div>
                        <div className="product__main-item--text">
                            {t('web.affiliate:English, Russian, Portuguese, Polish, Danish, French.')}
                        </div>
                    </div>
                    <div className="product__main-item">
                        <div className="product__main-item--name">
                            {t('web.affiliate:RESTRICTED COUNTRIES')}
                        </div>
                        <div className="product__main-item--text">
                            {t('web.affiliate:United States of America, Greece, United Kingdom, Jersey, Gibraltar, Spain, France and its overseas')}
                        </div>
                    </div>
                    <div className="product__main-item">
                        <div className="product__main-item--name">
                            {t('web.affiliate:CURRENCIES')}
                        </div>
                        <div className="product__main-item--text">
                            {t('web.affiliate:Australian dollars, Canadian Dollar, Euros, Norwegian kroner, Russian rubles, US dollars')}
                        </div>
                    </div>
                    <div className="product__main-item">
                        <div className="product__main-item--name">
                            {t('web.affiliate:RESTRICTED COMPAINGS')}
                        </div>
                        <div className="product__main-item--text">
                            {t('web.affiliate:Porn related content, Email marketing, cold calling, OSH naming in compaings.')}
                        </div>
                    </div>

                    <div className="product__main-item">
                        <div className="product__main-item--name">
                            {t('web.affiliate:Exclusive Gifts for Our Partners')}
                        </div>
                        <div className="product__main-item--text">
                            {t('web.affiliate:Level 1 - Apple AirPods; Level 2 - Apple Watch; Level 3 - iPad; Level 4 - MacBook Air; Level 5 - iPhone; Level 6 - MacBook Pro')}
                        </div>
                    </div>
                    <div className="product__main-item">
                        <div className="product__main-item--name">
                            {t('web.affiliate:To join')}
                        </div>
                        <div className="product__main-item--text">
                            {t('web.affiliate:Message our affiliate managers or sign up using our sign-up form.')}
                        </div>
                    </div>
                    <div className="product__main-item">
                        <div className="product__main-item--name">
                            {t('web.affiliate:Why Choose Us?')}
                        </div>
                        <div className="product__main-item--text">
                            {t('web.affiliate:Most competitive casino offers in the market; GEOs: Canada and Global for Crypto')}
                        </div>
                    </div>
                    <div className="product__main-item">
                        <div className="product__main-item--name">
                            {t('web.affiliate:Join today')}
                        </div>
                        <div className="product__main-item--text">
                            <div className="product__main-item--text">
                                {t('web.affiliate:Start earning with the most attractive affiliate program around!')}
                            </div>
                            <a href='https://oshcasino.com/en/documents/terms-and-conditions' target="_blank"
                               rel="noopener noreferrer"
                               className="team__main-item--mail">{t('web.affiliate:Terms and Conditions')}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="comments">
                <div className="comments__header">
                    <h6 className="comments__header--text">{t('web.affiliate:WHAT OTHERS SAY')}</h6>
                    <h4 className="comments__header--name">{t('web.affiliate:TESTIMONIALS')}</h4>
                </div>
                <div className="comments__main">
                    <div className="comments__main-item">
                        <img draggable="false"
                             className="comments__main-item--decoration"
                             src="/decorations/decorations8.png"
                             alt="decoration"
                             loading="lazy"
                        />
                        <div
                            className="comments__main-item--text">{t('web.affiliate:The OSH Affiliates is fantastic to work with! The platform is easy to use, and the analytics make it simple to track the progress. Plus, their games and design attract players effortlessly. The commission structure is transparent and lucrative, making this partnership not only enjoyable but also very profitable. I highly recommend the OSH Affiliates to anyone looking for a reliable collaboration.')}</div>
                        <div className="comments__main-item--name">
                            <span>{t('web.affiliate:CRAZYDOGZ team')}</span> &nbsp;
                            {t('web.affiliate:SWEDEN')}
                        </div>
                    </div>
                    <div className="comments__main-item">
                        <img draggable="false"
                             className="comments__main-item--decoration"
                             src="/decorations/decorations8.png"
                             alt="decoration"
                             loading="lazy"
                        />
                        <div
                            className="comments__main-item--text">{t('web.affiliate:The OSH Affiliates is truly delightful! Their user-friendly platform and in-depth analytics make tracking a breeze, while their attractive design and wide variety of games effortlessly draw in players. The clear and rewarding commission structure not only makes this partnership enjoyable but also incredibly profitable. I wholeheartedly suggest the OSH Affiliates  to anyone who is seeking a trustworthy partnership!')}</div>
                        <div className="comments__main-item--name">
                            <span>{t('web.affiliate:DIAMONDS777')}</span> &nbsp;
                            {t('web.affiliate:POLAND')}
                        </div>
                    </div>
                    <div className="comments__main-item">
                        <img draggable="false"
                             className="comments__main-item--decoration"
                             src="/decorations/decorations8.png"
                             alt="decoration"
                             loading="lazy"
                        />
                        <div
                            className="comments__main-item--text">{t('web.affiliate:Partnering with OSH casino, We expanded reach and boosted earnings. Their rewarding affiliate program provides tools for success. OSH casino offers ongoing support, which has proven invaluable to our success.')}</div>
                        <div className="comments__main-item--name">
                            <a href="https://revpanda.com/"
                               target="_blank"
                               rel="noopener noreferrer"
                               className="comments__main-item--link"
                            >
                                {t('web.affiliate:revpanda.com')}
                            </a> &nbsp;
                            {t('web.affiliate:Worldwide')}
                        </div>
                    </div>
                    <div className="comments__main-item">
                        <img draggable="false"
                             className="comments__main-item--decoration"
                             src="/decorations/decorations8.png"
                             alt="decoration"
                             loading="lazy"
                        />
                        <div
                            className="comments__main-item--text">{t('web.affiliate:Working with the OSH Affiliates is a game-changer! The appealing design and variety of games attract players. Plus, the platform is simple to navigate and the analytics help you stay on top of things. Speaking of the commission structure, it provides players not only pleasant gaming experience but you also earn well. If you’re seeking a trustful and reliable partnership, the OSH Affiliates is the place to be. ')}</div>
                        <div className="comments__main-item--name">
                            <span>{t('web.affiliate:John BLESS LTD')}</span> &nbsp;
                            {t('web.affiliate:CYPRUS')}
                        </div>
                    </div>
                    <div className="comments__main-item">
                        <img draggable="false"
                             className="comments__main-item--decoration"
                             src="/decorations/decorations8.png"
                             alt="decoration"
                             loading="lazy"
                        />
                        <div
                            className="comments__main-item--text">{t('web.affiliate:OSH Affiliates is outstanding! The support is top-notch, and the commission structure is generous. Our earnings have steadily increased since joining—highly recommended!')}</div>
                        <div className="comments__main-item--name">
                            <a href="https://www.casinoslists.com/news/osh-casino-best-new-casino-of-the-month-august-2024"
                               target="_blank"
                               rel="noopener noreferrer"
                               className="comments__main-item--link"
                            >
                                {t('web.affiliate:casinoslists.com')}
                            </a> &nbsp;
                            {t('web.affiliate:WORLDWIDE')}
                        </div>
                    </div>
                    <div className="comments__main-item">
                        <img draggable="false"
                             className="comments__main-item--decoration"
                             src="/decorations/decorations8.png"
                             alt="decoration"
                             loading="lazy"
                        />
                        <div
                            className="comments__main-item--text">{t('web.affiliate:PlayCanadaOnline is excited to announce our partnership with Osh Casino, a leader in the online gaming industry. We wholeheartedly endorse Osh Casino for their exceptional customer service and innovative affiliate revenue share program, which is open to the public and offers lucrative opportunities. Their commitment to excellence makes them a top choice for both players and affiliates alike.')}</div>
                        <div className="comments__main-item--name">
                            <a href="https://playcanadaonline.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="comments__main-item--link"
                            >
                                {t('web.affiliate:playcanadaonline.com')}
                            </a> &nbsp;
                            {t('web.affiliate:CANADA')}
                        </div>
                    </div>
                </div>
            </div>
            <div className="team">
                <div className="team__header">
                    <h6 className="team__header--text">{t('web.affiliate:MEET THE')}</h6>
                    <h4 className="team__header--name">{t('web.affiliate:HEAD OF AFFILIATES')}</h4>
                </div>
                <div className="team__main">
                    <div className="team__main-item">
                        <div className="team__main-item--info">
                            <div className="team__main-item--photo">
                                <img draggable="false"
                                     src="/contacts/oshcharacter.png"
                                     alt="decoration"
                                     loading="lazy"
                                />
                            </div>
                            <div className="team__main-item--info-container">
                                <div className="team__main-item--name">{t('web.affiliate:Sergejs P.')}</div>
                                <div className="team__main-item--position">{t('web.affiliate:Head of Affiliates')}</div>
                            </div>
                        </div>
                        <div className="team__main-item--contact">
                            <a href="mailto:affiliates@oshcasino.com" className="team__main-item--mail">
                                <img draggable="false"
                                     src="/svg/mail.svg"
                                     alt="decoration"
                                     loading="lazy"
                                />
                                <span>
                                    {t('web.affiliate:affiliates@oshcasino.com')}
                                </span>
                            </a>
                        </div>
                    </div>
                    <div className="team__main-item">
                        <div className="team__main-item--info">
                            <div className="team__main-item--photo">
                                <img draggable="false"
                                     src="/contacts/oshcharacterW.jpg"
                                     alt="decoration"
                                     loading="lazy"
                                />
                            </div>
                            <div className="team__main-item--info-container">
                                <div className="team__main-item--name">{t('web.affiliate:Lila Thornbrook')}</div>
                                <div className="team__main-item--position">{t('web.affiliate:Affiliate Manager')}</div>
                            </div>
                        </div>
                        <div className="team__main-item--contact">
                            <a href="mailto:Lila.Thornbrook@oshcasino.com" className="team__main-item--mail">
                                <img draggable="false"
                                     src="/svg/mail.svg"
                                     alt="decoration"
                                     loading="lazy"
                                />
                                <span>
                                    {t('web.affiliate:Lila.Thornbrook@oshcasino.com')}
                                </span>
                            </a>
                            <a href="skype:live:.cid.a1ad75c30f4d6341?chat" className="team__main-item--skype">
                                <img draggable="false"
                                     src="/contacts/skype.png"
                                     alt="decoration"
                                     loading="lazy"
                                />
                                <span>
                                    {t('web.affiliate:@live:.cid.a1ad75c30f4d6341')}
                                </span>
                            </a>
                            <a href="https://t.me/lilathornbrook" className="team__main-item--telegram">
                                <img draggable="false"
                                     src="/contacts/telegram.png"
                                     alt="decoration"
                                     loading="lazy"
                                />
                                <span>
                                    {t('web.affiliate:@lilathornbrook')}
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contactUs" id="contactUs">
                <div className="team__header">
                    <h6 className="team__header--text">{t('web.affiliate:Let’s start our journey')}</h6>
                    <h4 className="team__header--name">{t('web.affiliate:CONTACT US')}</h4>
                </div>
                <form onSubmit={handleSubmit} className="input-form">
                    <div className="input-info-container">
                        <div className="input-container">
                            <label className="input-label">{t('web.affiliate:Name')}</label>
                            <input
                                type="text"
                                className={`input__info ${nameError && "input__error"}`}
                                onChange={(e) => setName(e.target.value)}
                            />
                            {nameError && (
                                <div className="error-message">{nameError}</div>
                            )}
                        </div>
                        <div className="input-container">
                            <label className="input-label">{t('web.affiliate:Email')}</label>
                            <input
                                type="email"
                                className={`input__info ${emailError && "input__error"}`}
                                autoComplete='on'
                                onChange={(e) => setEmail(e.target.value)}
                            />
                            {emailError && (
                                <div className="error-message">{nameError}</div>
                            )}
                        </div>
                    </div>
                    <div className="input-container">
                        <label className="input-label">{t('web.affiliate:Your message')}</label>
                        <textarea
                            className={`input__text ${messageError && "input__error"}`}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                        {messageError && (
                            <div className="error-message">{nameError}</div>
                        )}
                    </div>
                    <div className="input-button">
                        <button
                            className={`main-button ${sended && "button-disabled"}`}
                            type="submit"
                            disabled={sended}
                        >
                        {sended
                            ? t('web.affiliate:SENDED')
                            : t('web.affiliate:SEND')
                            }
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
  );
}

export default App;
